export const formatDate3 = (date) => {
    const now = new Date(date);
    let month = (now.getMonth() + 1);
    let day = now.getDate();
    if (month < 10)
        month = "0" + month;
    if (day < 10)
        day = "0" + day;
    const today = now.getFullYear() + '-' + month + '-' + day + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    return today;
}

export const copyToclipboard = (value) => {
    var input = document.createElement("input");
    input.value = value;
    document.body.appendChild(input);
    input.select();  
    document.execCommand('copy');
    document.body.removeChild(input);
    return true;
}