import Card from 'react-bootstrap/Card';

const Passerelle = (props) => {
  return (
    <Card className="text-center">
      {/* <Card.Header>{props.passerelle.name}</Card.Header> */}
      <Card.Body style={{padding: "5px"}}>

        {(props.passerelle.cashPoint && props.type === "depot") && <>
          <h5>Composez ce code pour vos dépôt</h5>
          <h5>{props.passerelle.operateur.map((o, index) => <span key={`opc${index}`} className='text-success'>#111*1*4*1*{o.phone}*{props.amount}#<br />({o.name})</span>)}</h5>
        </>}
        {(!props.passerelle.cashPoint && !props.passerelle.tpe && props.type === "depot") && <>
          <h6>Transférez exactement la somme de <span className='text-danger'>{props.amount} Ariary</span> (sans frais de retrait) au: </h6>
          <h6 style={{marginBottom: '0px'}}>{props.passerelle.operateur.map((o, index) => <span key={`opp${index}`} className='text-success'>{`${o.phone} (${o.name})`}<br /></span>)}</h6>
        </>}
        {(props.passerelle.auto && props.passerelle.tpe && props.type === "depot") && <Card.Text>
          <h4>Veuillez maintenir votre téléphone allumé, et accepter la demande de débit envoyée par <br />{props.passerelle.operateur.map((o, index) => <span key={`opn${index}`} className='text-success'>{`${o.name} (${o.phone})`}<br /></span>)}</h4>
        </Card.Text>}
      </Card.Body>
      {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
    </Card>
  );
}

export default Passerelle;