import './App.css';
import GateWay from './GateWay';
import { Route, Routes } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

function App() {
  return (
    <Container>
      <div className="App">
        <Routes>
          <Route path="/" element={<GateWay />} />
        </Routes>
      </div>
      <Row>
        <Col className="text-center mt-5" style={{fontSize: '10px'}}>
          SLife GATEWAY - Moyen de paiement & transfert automatisé<br/>
          © 2025{/* , Powered by{" "}
          <a href="https://slifetracker.mg/" target="_blank" rel="noreferrer">
            SLife Tracker
          </a>{" "} */}
          , Developed with <i className="fa fa-heart text-danger"></i>{" "} by {" "}
          <a href="https://www.facebook.com/trivomanana/" target="_blank" rel="noreferrer">
            TOKINIAINA T.R
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
