import { Fragment, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { httpGetRequest } from "./HttpRequestService";
import Passerelle from "./Passerelle";
import { copyToclipboard } from "./function";



const GateWay = (props) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const key_public = query.get("public");
    const validKey = key_public && key_public.trim() !== "" ? true : false;

    const [erreur, setErreur] = useState('');
    const [trx, setTrx] = useState(null);
    const [copieIns, setCopieIns] = useState(false);

    const haveErreur = erreur !== '' ? true : false;

    const checkTrx = async (trxTmp = null) => {
        try {
            const response = await httpGetRequest(`CheckTrx/${key_public}`, false);
            if (response.data) {
                if(!trxTmp){
                    setTrx(response.data);
                    trxTmp = response.data;
                }
                if (trxTmp && response.data.status !== trxTmp.status) {
                    if (response.data.status === 'completed') {
                        window.location.replace(`${trxTmp.uriSuccess}?action=dépôt&message=votre dépôt a bien été effecuté&status=success`);
                    } else if (response.data.status === 'canceled') {
                        window.location.replace(`${trxTmp.uriCanceled}?action=dépôt&message=Dépôt annuler&status=error`);
                    }
                    setTrx({ ...trxTmp, status: response.data.status });
                    setTimeout(() => {
                        checkTrx(response.data);
                    }, 10000);
                } else {
                    setTimeout(() => {
                        checkTrx(trxTmp);
                    }, 10000);
                }
            }
            //setLoading(false);
        } catch (error) {
            window.location.replace(`${trxTmp.uriCanceled}?action=dépôt&message=Dépôt annuler&status=error`);
            //setLoading(false);
            if (error.response) {
                setErreur(error.response.data.message)
            };
        }
    }


    useEffect(() => {
        if(validKey){
            checkTrx();
        }
    }, []);

    return (
        <Container>
            <Row>
                <Col></Col>
                <Col xs={12} sm={12} lg={6}>
                    {(trx && trx.meta_data.find(m => m.key === "uriLogo")) && <img alt="logo" src={trx.meta_data.find(m => m.key === "uriLogo").value.trim()} style={{ width: "130px", margin: "20px" }} />}
                    <h6>{(trx && trx.meta_data.find(m => m.key === "description")) && trx.meta_data.find(m => m.key === "description").value.trim()}</h6>
                    <h6>SESSION: <strong>{key_public}</strong></h6>
                    {trx && <h6 className="text-danger"><i className="fa fa-exclamation-triangle text-danger"></i> Expiration à {trx.expiredAt}</h6>}
                    {trx && <h6>Montant à {trx.type === "depot" ? 'déposer' : 'transférer'}: <strong className='text-success'>{trx.amount} Ariary</strong></h6>}
                    {(trx && trx.meta_data.find(m => m.key === "phoneNumber")) && <h6 className='text-danger'>Effectuer votre opération uniquement à partir de ce numéro : <strong>{trx.meta_data.find(m => m.key === "phoneNumber").value}</strong></h6>}
                    {(trx && trx.meta_data.find(m => m.key === "instruction")) && <h6>Instruction : <strong className='text-success'>{trx.meta_data.find(m => m.key === "instruction").value}</strong></h6>}
                    {(trx && trx.meta_data.find(m => m.key === "instruction")) && <div className="text-center" style={{marginBottom: '10px'}}>
                        <Button onClick={() => {const cop = copyToclipboard(trx.meta_data.find(m => m.key === "instruction").value); setCopieIns(cop)}} variant={copieIns ? "outline-success": "outline-danger"} className="btn-sm">{copieIns ? <i className="fa fa-thumbs-up text-success"></i>: ""} {copieIns ? "Copié": "Copier l'instruction"}</Button>
                    </div>}
                </Col>
                <Col></Col>
            </Row>
            {!haveErreur && <>
                
                {trx?.passerelle.map((p, index) => <Fragment key={index}>
                    <Passerelle key={`pas${index}`} type={trx.type} passerelle={p} amount={trx.amount} />
                </Fragment>)}
                {trx && <Row>
                    <Col></Col>
                    <Col xs={12} sm={12} lg={6}>
                    <br />
                        {trx.status === 'pending' && <Spinner size="sm" animation="grow" variant="danger"/>}
                        {(trx.status === 'checking' || trx.status === 'processing') && <Spinner size="sm" animation="grow" variant="warning" />}
                        {trx.status === 'completed' && <Spinner size="sm" animation="grow" variant="success" />}

                        <p>{trx.status === 'pending' && "En attente de paiement"}
                            {trx.status === 'checking' && "En cours de vérification"}
                            {trx.status === 'processing' && "En cours de paiement"}
                            {trx.status === 'canceled' && "Paiement annulé"}
                            {trx.status === 'completed' && "Paiement reçu"}</p>
                    </Col>
                    <Col></Col>
                </Row>}
            </>}
            { /* <Row>
                <Col></Col>
                <Col xs={12} sm={12} lg={6}>
                    <div style={{width: "100%",height: "183px", 'marginTop':"20px"}} className="justify-content-center">
                        <Alert variant="warning">
                        Pour les transferts supérieurs à 300WMZ ou WME, veuillez transférer manuellement aux compte suivants: <strong>Z983842492075</strong> pour WMZ et <strong>E118880917275</strong> pour WME. Mettez comme référence votre numéro de compte UNEX .
                        </Alert>

                    </div>
                </Col>
                <Col></Col>
            </Row> */}
            <Row>
                <Col></Col>
                {haveErreur && <Col xs={12} sm={8} lg={4}>
                    <div style={{ width: "100%", height: "183px", 'marginTop': "20px" }} className="justify-content-center">
                        <Alert variant="warning">
                            {erreur}
                        </Alert>
                    </div>
                </Col>}
                <Col></Col>
            </Row>
            {trx && <div className="text-center">
                <Button href={`${trx.uriCanceled}?action=dépôt&message=Dépôt annuler&status=error`} variant="outline-primary" className="btn-sm">Retour vers Espace Client</Button>
            </div>}
            {trx && <Row>
                    <Col></Col>
                    <Col xs={12} sm={12} lg={8}>
                    <br />
                        <p className="text-danger" style={{fontSize: '12px', marginBottom: "0px"}}>En cas d'incident : {trx.messageIncident} et mentionné le numéro de SESSION.</p>
                    </Col>
                    <Col></Col>
                </Row>}
        </Container>
    )
}

export default GateWay;